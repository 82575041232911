import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import {Button} from '../ButtonElements';
import {Button as SubButton} from '../BlueButtonElements';
import './Contact.css';
import { Container, Row, Col, Card, CardBody, CardFooter, CardImg, CardTitle, CardText } from 'reactstrap';
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';


// Email validation
const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

// Form validation
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // Validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === '' && (valid = false);
  });

  return valid;
};


const DangerToast = (string) => {
  return toast(string, {
    autoClose: 4000,
    hideProgressBar: true,
    className: "dangerToast",
    transition: Bounce,
    position: 'bottom-center',

  });
}

const SuccessToast = (string) => {
  return toast(string, {
    autoClose: 4000,
    hideProgressBar: true,
    className: "successToast",
    transition: Bounce,
    position: 'bottom-center',
  });
}

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: 'Website Design',
      message: '',
      formErrors: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
    };
  }

  

  handleSubmit = (e) => {
    e.preventDefault();

    if (formValid(this.state)) {
      // Handle form validation success
      const { name, email, subject, message } = this.state;

      // Set template params
      let templateParams = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      };

      //send email through emailjs
      SuccessToast("Thanks For Getting In Touch ");
      emailjs.send('service_TR','template_sj7nuqf', templateParams, 'user_km77ST7KYBHAWMF3NGyF3'); // UNCOMMENT THIS TO SEND 

      console.log(`
        --SUBMITTING--
        Name: ${name}
        Email: ${email}
        Subject: ${subject}
        Message: ${message}
      `);

      this.resetForm();
    } else {
      // Handle form validation failure
      DangerToast("Error Submitting Form, Please Try Again");

      console.error('FORM INVALID - DISPLAY ERROR MESSAGE');
    }
  };

  // Function to reset form
  resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case 'name':
        formErrors.name = value.length < 1 ? 'Please enter your name.' : '';
        break;
      case 'email':
        formErrors.email = emailRegex.test(value) ? '' : 'Please enter a valid email address.';
        break;
      case 'subject':
        formErrors.subject = value.length < 1 ? 'Please enter a subject.' : '';
        break;
      case 'message':
        formErrors.message = value.length < 1 ? 'Please enter a message' : '';
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    const { formErrors } = this.state;

    return (
      <div className='ContactForm'>
      <Container>
        <form id='contact-form' onSubmit={this.handleSubmit} noValidate>
          <Row>
            <Col xs="12" md="12" lg="12">
            <label> Name </label>
              <input
                type='text'
                name='name'
                value={this.state.name}
                className={`form-control formInput ${formErrors.name.length > 0 ? 'error' : null}`}
                onChange={this.handleChange}
                placeholder='Name'
                noValidate
              ></input>
              {formErrors.name.length > 0 && (
                <span className='errorMessage'>{formErrors.name}</span>
              )}
            </Col>

            <Col xs="12" md="12" lg="12">
            <label> Email Address </label>
              <input
                type='email'
                name='email'
                value={this.state.email}
                className={`form-control formInput ${formErrors.email.length > 0 ? 'error' : null}`}
                onChange={this.handleChange}
                placeholder='Your Email'
                noValidate
              ></input>
              {formErrors.email.length > 0 && (
                <span className='errorMessage'>{formErrors.email}</span>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="12" lg="12">
              <label> Select Service </label>
              <select
                type='subject'
                name='subject'
                value={this.state.subject}
                className={`form-control formInput ${
                  formErrors.subject.length > 0 ? 'error' : null
                }`}
                onChange={this.handleChange}
                noValidate
              >
              <option disabled value> -- Select A Service -- </option>
              <option> Website Design </option> 
              <option> Website Management </option>
              <option> Search Engine Optimization  </option>
              <option> Web Application Development </option>
              <option> General Enquiry </option>

              </select>
              {formErrors.subject.length > 0 && (
                <span className='errorMessage'>{formErrors.subject}</span>
              )}
            </Col>

            <Col xs="12" md="12" lg="12">
            <label> Message </label>
              <textarea
                rows='5'
                name='message'
                value={this.state.message}
                className={`form-control formInput ${
                  formErrors.message.length > 0 ? 'error' : null
                }`}
                onChange={this.handleChange}
                placeholder='Message'
                noValidate
              ></textarea>
              {formErrors.message.length > 0 && (
                <span className='errorMessage'>{formErrors.message}</span>
              )}
            </Col>
          </Row>
          <button className='submit-btn' type='submit'>
            Submit
          </button>
          <ToastContainer/>
        </form>
        </Container>
       
      </div>
    );
  }
}

export default ContactForm;