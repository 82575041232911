import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Button = styled.a`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01bf71' : '#01bf71')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '15px 32px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px':'16px')};
    outline:none;
    border:none;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    font-weight: bold;
    &:hover {
        text-decoration: none !important;
        transition: all 0.2s ease-in-out;
        color: #fff;
        background: ${({primary}) => (primary ? '#fff':'#101522')}
    }
`

