// rafce
import React, {useState, useEffect } from 'react';
import {FaBars} from 'react-icons/fa';
import {animateScroll as scroll} from 'react-scroll';
import {Nav, NavbarContainer, NavLogo, BurgerMenu, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from './NavbarElements';
import {IconContext} from 'react-icons/lib';
import { Button } from '../ButtonElements';


const Navbar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 90) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav);
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <React.Fragment>
        <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo onClick={toggleHome} > TR Web Engineering </NavLogo>
                    <BurgerMenu onClick={toggle}>
                    <FaBars/>
                    </BurgerMenu>
                    <NavMenu>
                        <NavItem scrollNav={scrollNav}> 
                            <NavLinks to="about" smooth={true} duration={500} spy={"true"} exact='true' offset={-79}> About </NavLinks>
                        </NavItem>
                          <NavItem scrollNav={scrollNav}> 
                            <NavLinks to="portfolio" smooth={true} duration={500} spy={"true"} exact='true' offset={-79}> Portfolio </NavLinks>
                        </NavItem>
                        <NavItem scrollNav={scrollNav}> 
                        <NavLinks to="contact" smooth={true} duration={500} spy={"true"} exact='true' offset={-79} className="hideMe"> Contact </NavLinks>
                        </NavItem>
                    
                    </NavMenu>

                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </React.Fragment>
    );
}

export default Navbar


                            // <NavLinks to="gallery" smooth={true} duration={500} spy={"true"} exact='true' offset={-80}> Gallery </NavLinks>
                    // <NavBtn>
                    //     <Button to="contact" smooth={true} duration={500} spy={"true"} exact='true' offset={-80} className="DarkBtn" dark={true}> Build My Website</Button>
                    // </NavBtn>