import {FooterContainer, FooterWrap,FooterLinksContainer,FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink,
        SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements';
import {FaFacebook, FaYoutube, FaLinkedin, FaInstagram, FaArrowUp} from 'react-icons/fa';
import React, {useState, useEffect } from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {IconContext} from 'react-icons/lib';


// each footerLinksWrapper is a column

const Footer = () => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 90) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav);
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
        <FooterContainer>
            <FooterWrap>      
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo onClick={toggleHome}>
                         TR Web Engineering 
                        </SocialLogo>
                        <WebsiteRights> TR Web Engineering © {(new Date().getFullYear())} All Rights Reserved </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.instagram.com/trwebengineering" target="_blank" aria-label="Instagram">
                            <FaInstagram/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>

                
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer

// <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
// <FaLinkedin/>
// </SocialIconLink>
// <SocialIconLink href="/" target="_blank" aria-label="Facebook">
// <FaFacebook/>
// </SocialIconLink>
// <SocialIconLink href="/" target="_blank" aria-label="YouTube">
// <FaYoutube/>
// </SocialIconLink>