import React from 'react'
import ContactForm from './ContactForm';
import {SectionWrapper, SectionInner, InnerLeft, InnerRight, H1} from './SectionElements';
import {UnderLine} from '../GlobalElements';
import { Container, Row, Col, Card, CardBody, CardFooter, CardImg, CardTitle, CardText } from 'reactstrap';
import { Component } from 'react';
import {FaPhone} from 'react-icons/fa';
import {Paragraph, SubParagraph, Heading,} from './SectionElements';
import { Button, ButtonL } from '../ButtonElements';


const Contact = () => {

    


    return (
        <SectionWrapper id="contact">
            
              <Row>
                <Col className="Col" sm={12} md={5} lg={6}> 
                    <InnerLeft>
                    
                    <h2> Contact</h2>
                    <p> Get In Contact To Get Your Free Quote Today, So We Can Get Your <strong> Dream Website </strong>Online.</p>
                    </InnerLeft>
                    <ButtonL primary={false} style={{'margin-right' : "2%"}} href="tel:07512282997"> Call Us</ButtonL> <ButtonL primary={false} href="mailto: tim.rayner2020@gmail.com"> Email Us </ButtonL>
                </Col>

                <Col className="Col" sm={12} md={7} lg={6}>
                    <ContactForm/>
                </Col>
            </Row>
          
            
        </SectionWrapper>
    )
}

export default Contact
