import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import { Card, Row, Col } from 'reactstrap';
import { FaStar } from 'react-icons/fa';

export const SectionWrapper = styled.section`
    min-height:100vh; //should be 102
    height: 100%;
    background: #101522 ;
    color: #fff;
    text-align:center;
    align-content:center;
    justify-content:center;
    position: relative;
    border: none;
`

export const Heading = styled.h2`
    padding: 4% 0; 
    font-size: 2.9rem;
    font-weight: 900;
`
export const PortfolioRow = styled(Row)`
    padding: 2% 5%;
    padding-top: 5.5%;
`

export const PortfolioCol = styled(Col)`
   
    align-items: center;
    text-align: center;
    margin: 5% auto;
    display: grid;
    grid-auto-flow: column;
    min-height:35vh;
    //md
    @media only screen and (min-width:45em) {
        margin: 2.5% auto;
    }
    @media only screen and (min-width:64.25) {
        margin: 0 auto;
    }
    
`

export const PortfolioItem = styled.section`
    position: relative;
    background: rgba(255,255,255,0.45);
    border-radius: 2.5%;
    height:100%;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px;
    transition: all ease-in-out 300ms;
    text-decoration: none;
    @media only screen and (min-width:45em) {
        margin: 2.5% auto;
    }
    @media only screen and (min-width:64.25) {
        margin: 0 auto;
    }
 
    &:hover {
        box-shadow: 0px 54px 55px -15px rgba(255,255,255,0.2);
        transform: translate(0px, -10px);
    }

`

export const PortfolioImage = styled.img`
    height:100%;
    width:100%;
    border-radius: 2.5%;
    //IMAGE-RENDERING - THIS NEEDS TESTING
    @media only screen and (min-width:45em) {
        image-rendering: initial;
    }
    @media only screen and (min-width:64.25) {
        margin: 0 auto;
        
        image-rendering: initial;
    }
`

export const PorfolioOverlayA = styled.a`
    background-color: rgba(16, 21, 34, 0.75);
    border-radius: 2.5%;
    color: #101522;
    text-decoration: none;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0;
    transition: 0.4s ease-in-out;
    vertical-align: middle;
    padding: 15% 2.5%2.5%;
    text-align:center;
    &:hover{
        opacity: 1;
        display: block;
        cursor: pointer;
    }
`
export const PorfolioOverlayS = styled(LinkS)`
    background-color: rgba(16, 21, 34, 0.75);
    border-radius: 2.5%;
    color: #101522;
    text-decoration: none;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0;
    transition: 0.4s ease-in-out;
    vertical-align: middle;
    padding: 15% 2.5%2.5%;
    text-align:center;
    &:hover{
        opacity: 1;
        display: block;
        cursor: pointer;
    }
`


export const OverlayHeader = styled.h3`
    opacity: 1;
    color: #fff;
    font-weight: 800;
    //
    font-size: 1.75em;
    padding: 0 5%;
    font-weight: 600;
`

export const OverlayText = styled.p`
    opacity: 1;
    color: #fff;
    font-weight: 800;
    padding: 5%;
    padding-top: 15%;
`

export const ReviewHeader = styled.h3`
    font-size: 1.75em;
    padding:  2.5% 25%;
    text-align: center;
`

export const P = styled.p`
    padding: 5%;
    padding-top: 2.5%;
    font-size: 1.45em;
    line-height: 35px;
    @media only screen and (min-width:45em) {
        padding: 5% 25%;
        padding-top: 1.5%;
    }
    @media only screen and (min-width:64.25) {
        margin: 0 auto;
        padding: 5% 25%;
        padding-top: 1.5%;
    }
`


export const StarList = styled.ul`
    list-style: none;
    display: inline-flex;
`
export const CheckedStar = styled(FaStar)`
    font-size: 2em;
    color: orange;
    
`