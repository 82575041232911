import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import About from '../components/About';
import Contact from '../components/Contact';
import Portfolio from '../components/Portfolio';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
   

    const toggle = () => {
        setIsOpen(!isOpen)
     
    };

    return (
        <React.Fragment>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar isOpen={isOpen} toggle={toggle}/>
            <main>
                <HeroSection/>
                <About/>
                <Contact/>
            </main>
            <Footer/>
        </React.Fragment>
    );
};

export default Home
