import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    min-height: 103vh;
    position: relative;
    z-index: 1;
    overflow: hidden;

    //dark overlay
    :before {
        content:'';
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100% ),
        linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%);
        z-index: 2;
        
    }
    /* Add :before styles */ 
`

export const HeroBg = styled.div`
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;
    //opacity:0.65;
`
export const VideoBg = styled.video`
    width:100%;
    height:100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position:absolute;
    padding: 8px 24px;
    display:flex;
    flex-direction: column;
    align-items:center;
`

export const HeroH1 = styled.h1`
    color: #fff;
    text-align:center;
    font-family: 'Encode Sans Expanded',
    sans-serif;
 
`
export const HeroP = styled.p`
    margin-top: 48px;
    color: #fff;
    font-size:24px;
    text-align: center;
    max-width: 600px;
    font-weight: 600;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`



export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`


export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items:center;
`

export const MouseWrapper = styled.section`
    position: absolute;
    bottom: -23.5vh;
    @media only screen and (min-width:45em) {
        bottom: -30vh;
      }
      //lg
      @media only screen and (min-width:64.25em) {
        bottom: -30vh;
      }
`   


export const MouseText = styled.h3`
    text-align: center;
    
`

export const MouseDown = styled.div`

    width: 60px;
	height: 60px;
	border: 2px solid #fff;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #fff;
  	border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
	}

    @keyframes down {
	    0% {
	    	transform: translate(0);
	    }
	    20% {
	    	transform: translateY(15px);
	    }
	    40% {
	    	transform: translate(0);
	    }
    }

@-webkit-keyframes down {
	    0% {
	    	transform: translate(0);
	    }
	    20% {
	    	transform: translateY(15px);
	    }
	    40% {
	    	transform: translate(0);
	    }
    }

`  


//original 
// width: 50px;
// height: 90px;
// border: 3px solid #333;
// border-radius: 60px;
// position: relative;
// &::before {
//     content: '';
//     width: 12px;
//     height: 12px;
//     position: absolute;
//     top: 10px;
//     left: 50%;
//     transform: translateX(-50%);
//     background-color: #333;
//     border-radius: 50%;
//     opacity: 1;
//     animation: wheel 2s infinite;
//     -webkit-animation: wheel 2s infinite;
// }