import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';


export const CenteredUnderLine = styled.span`
    background-image: linear-gradient(120deg, #01bf71 0%, #01bf71 100%);
    background-repeat: no-repeat;
    display: inline-flex;
    margin-top: 5%;
    background-size: 75% 0.25em;
    background-position: 50% 100%;

    transition: 0.15s ease-in;
    padding: 2%;
    margin: 2%;
    margin-bottom: 2.5%;
`

export const UnderLine = styled.span`
    background-image: linear-gradient(120deg, #01bf71 0%, #01bf71 100%);
    background-repeat: no-repeat;
    display: inline-flex;
    background-size: 75% 0.25em;
    background-position: 50% 100%;
    transition: 0.15s ease-in;
    padding-bottom: 0;
    @media only screen and (min-width:45em) {
    }
    //lg
    @media only screen and (min-width:64.25em) {
        background-size: 75% 0.25em;
        background-position: 0 100%;
    }
`

