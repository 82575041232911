import styled from 'styled-components';
import {Link as LinkS} from 'react-scroll';

export const Button = styled(LinkS)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01bf71' : 'rgba(1, 191, 113, 0.85)')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '15px 32px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px':'16px')};
    outline:none;
    border:none;
    cursor:pointer;
    display:inline-flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    font-weight: bold;
    min-width: 35%;
    margin: 2% auto;
    margin-bottom: 0;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff':'rgba(1, 191, 113, 1)')};
        color: #fff;
    }
`

export const ButtonL = styled.a`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#01bf71' : 'rgba(1, 191, 113, 0.85)')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '15px 32px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px':'16px')};
    outline:none;
    border:none;
    cursor:pointer;
    display:inline-flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    font-weight: bold;
    min-width: 35%;
    margin: 2% auto;
    margin-bottom: 0;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff':'rgba(1, 191, 113, 1)')};
        color: #fff;
    }
`

