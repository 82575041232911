import React from 'react'
import {SidebarContainer, 
        Icon,
        CloseIcon,
        SidebarWrapper,
        SidebarMenu,
        SidebarLink,
        SideBtnWrap,
        SidebarRoute,
        } from './SidebarElements'

const Sidebar = ( {toggle, isOpen} ) => {
    return (
        <SidebarContainer isOpen={isOpen} >
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}> About </SidebarLink>
                    <SidebarLink to="portfolio" onClick={toggle}> Portfolio </SidebarLink>
                    <SidebarLink to="contact" onClick={toggle}> Contact </SidebarLink>     
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
