import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import img from '../../images/laptopbackground.jpg'
import { Row, Col } from 'reactstrap';

export const SectionWrapper = styled.section`
    
    /* Sizing */
    width: 100vw;
    min-height: 55vh;
    height:100%;

    /* Flexbox stuff */
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* Text styles */
    text-align: center;
    color: white;
    
    /* Background styles */
    background-image: linear-gradient(rgba(16,21,34,0.75),rgba(16,21,34,0.75)), url(${img});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-bottom: 5%;
    &::before{
        z-index: -1;

        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
    }


`

export const SectionInner = styled(Row)`


`

export const InnerLeft = styled.article`

    margin: 25% auto;
    margin-bottom: 5%;
    
`

export const InnerRight = styled(Col)`

  
`

export const H1 = styled.h1`

    font-size: 2em;
    font-weight: 600;
`