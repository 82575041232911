import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import {Card, Col, Row} from 'reactstrap';
import { FaBalanceScale, FaBullseye, FaMobile, FaPeopleCarry, FaPhone } from 'react-icons/fa';

export const SectionWrapper = styled(Row)`
  
    background: #fff ;
    min-height: 100vh;
    text-align: start;
    color: black;
    overflow: hidden;
    padding: 5%;
    //
    
    
`
export const Heading = styled.h2`

   font-size: 2em;
   font-weight: 600;
   text-align: center;
   //lg
   @media only screen and (min-width:64.25em) {
     margin-bottom: 0;
     padding-bottom: 0;
     text-align: start;
   }
`

export const SubHeading = styled.h3`
  font-size: 1.75em;
  font-weight: 600;
  margin: 15% auto;
  margin-top: 20%;
  padding-left: 0;
  text-align: center;
  @media only screen and (min-width:45em) {
    margin: .5% auto;
  }
  //lg
  @media only screen and (min-width:64.25em) {
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: start;
  }
`

export const P = styled.p`
  font-size: 1.65em;
  padding: 5% 0%;
  padding-left: 0;
  margin: 0 auto;
  text-align: center;

  @media only screen and (min-width:45em) {
  
  }
  //lg
  @media only screen and (min-width:64.25em) {
    padding-top:2%;
    padding-right:20%;
    text-align: start;
  }

`

export const InnerLeft = styled(Col)`

`
export const InnerRight = styled(Col)`
  align-items: center;
  text-align: center;

`


export const Image = styled.img`
   width: 85%;
   transition:all .5s ease;
      color:#41403E;
      font-size:2rem;
      letter-spacing:1px;
      outline:none;
      box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
      border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
      border:solid 7px #101522;
      margin-top: 5%;
      @media only screen and (min-width:45em) {
       
      }
      //lg
      @media only screen and (min-width:64.25em) {
        margin-top: 0;

      }
`

export const FeaturesContainer = styled(Row)`
  // 
  padding: 5% 0;
  padding-bottom: 2.5%;
`

export const FeatureContainer = styled(Col)`  
  display: grid;
  grid-auto-flow: column;
  gap: 5%;
  margin-top: 2%;
  padding: 5% 0;
  //md
  @media only screen and (min-width:45em) {

    margin-top: 0;
    padding:2% 0;

  }
  //lg
  @media only screen and (min-width:64.25em) {
    margin-top: 0;
    padding:2% 0;

  }

`

export const Feature = styled(Card)`
    text-align: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    padding:3%;
    border: none;
    //md
    @media only screen and (min-width:45em) {
  
      margin-top: 0;
  
    }
    //lg
    @media only screen and (min-width:64.25em) {
      margin-top: 0;
      width: 75%;
    }

`

export const FeatureHeading = styled.h2`
    font-size: 1.5em;
    font-weight: 800;
    color: #101522;
    padding: 5% 0;
    padding-bottom: 2.5%;
    padding-top: 7%;
`

export const FeatureText = styled.p`
    font-size: 1.5em;
    font-weight: 500;
    color: #101522;
    padding: 0;
`



export const BulletList = styled.ul`

    padding: 2.5% 0;
    padding-left: 0;
    text-align: start;
    //md
    @media only screen and (min-width:45em) {
    margin-top: 0;
    }
    //lg
    @media only screen and (min-width:64.25em) {
      margin-top: 0;
      width: 75%;
      text-align: start;

    }
`

export const ListItems = styled.li`

    padding: 2%;
    padding-left: 0;
    font-size: 1.15em;
    padding-bottom: 5%;
    
    //lg
    @media only screen and (min-width:64.25em) {
      font-size: 1.35em;
      padding-bottom: 0;
    }
`

export const BulletItemIcon1 = styled(FaMobile)`
  font-size: 1.55em;
  margin-right: 3%;
`
export const BulletItemIcon2 = styled(FaPeopleCarry)`
  font-size: 1.55em;
  margin-right: 3%;
`
export const BulletItemIcon3 = styled(FaBullseye)`
  font-size: 1.55em;
  margin-right: 3%;
`