import React, {useState} from 'react'
import Video from '../../media/video.mp4';
import {Button} from '../ButtonElements';
import {HeroContainer,
        HeroBg, 
        VideoBg,
        HeroContent,
        HeroH1,
        HeroP,
        HeroBtnWrapper,
        ArrowRight,
        ArrowForward,
        MouseDown,
        MouseWrapper,
        MouseText
        } from './HeroElements';
import { NavLinks } from '../Navbar/NavbarElements';
import Typewriter from 'typewriter-effect';

const HeroSection = () => {
//build state for hover toggle
const [hover, setHover] = useState(false);
//build hover toggle
const onHover = () => {
    setHover(!hover);
};
    return (
       <HeroContainer className="hero" id="home">
        
            <HeroBg>
                <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent>
                <HeroH1> Bespoke Website Design </HeroH1>
                <HeroP>
                <span className="type-writer-inline">We Create Websites Which Are </span><span className="type-writer-inline highlight"> 
                <Typewriter
                    options={{
                    strings: ['Unique.', 'Responsive.', 'Professional.', 'Tools.', 'Outstanding.', 'Scalable.'],
                    autoStart: true,
                    loop: true,
                    
                    }}
                /> </span>
                </HeroP>
                <HeroBtnWrapper>
                
                    <MouseWrapper>
                  
                    <NavLinks to="about" smooth={true} duration={500} spy={"true"} exact='true' offset={-80}>     <MouseDown/>   </NavLinks>
              
                    </MouseWrapper>
                </HeroBtnWrapper>
            </HeroContent>
       </HeroContainer>
    )
}

export default HeroSection
