import React from 'react'
import {SectionWrapper, FeaturesContainer, FeatureContainer, Feature, FeatureImage,ListItems, FeatureHeading, FeatureText, Heading, P, InnerLeft, InnerRight, Image, SecondaryHeading, Services, SubHeading, BulletList, BulletItemIcon1, BulletItemIcon2, BulletItemIcon3 }from './SectionElements';
import {CenteredUnderLine, UnderLine} from '../GlobalElements';
import {Container, Row, Col } from 'reactstrap';
import Typewriter from 'typewriter-effect';
import { FaBusinessTime, FaCode, FaMobile, FaSearch } from 'react-icons/fa';
import { Button } from '../ButtonElements';
import Img1 from '../../images/MySmartMeeting.png';


const About = () => {
    return (
            
        <SectionWrapper id="about"> 
            <InnerLeft sm={12} md={12} lg={6}>
           <Heading> It's Not Just A Website... </Heading>
           <P> We Build <strong>Business Tools.</strong> Each Website Is Designed And Built Specifically To Suit, Sell and Promote Your Business. </P>

            <SubHeading> Why Pick TR Web Engineering?</SubHeading>
            <BulletList>
                <ListItems><span><i><BulletItemIcon1/></i></span> Responsive Design </ListItems>
                <ListItems><span><i><BulletItemIcon2/></i></span> Friendly & Efficient Team </ListItems>
                <ListItems><span><i><BulletItemIcon3/></i></span> Our Solutions Produce Results </ListItems>
            </BulletList>

            </InnerLeft>
            <InnerRight sm={12} md={12} lg={6}>
             <Image src={Img1} alt="MySmartMeeting Display"/>
            </InnerRight>
                <FeaturesContainer className=""> 
                {/* THERE IS A PROBLEM IN TABLET MODE THAT THESE FEATURES TITLES DO NOT ALIGN */}
                    <FeatureContainer sm={12} md={4} lg={4}>
                        <Feature>
                            <FaCode className="fontawesome"/>
                            <CenteredUnderLine><FeatureHeading> Website Development </FeatureHeading></CenteredUnderLine>
                            <FeatureText> Outstanding, Responsive Website Development. We build Business Tools That Produce Results. </FeatureText>
                        </Feature>
                    </FeatureContainer>
                    <FeatureContainer sm={12} md={4} lg={4}>
                    <Feature>
                        <FaSearch className="fontawesome"/>
                        <CenteredUnderLine><FeatureHeading> SEO Management </FeatureHeading></CenteredUnderLine>
                        <FeatureText> We Offer SEO Plans To Help Keep Your Website As High Up In The Result List As Possible. </FeatureText>
                    </Feature>
                </FeatureContainer>
                <FeatureContainer sm={12} md={4} lg={4}>
                <Feature>
                    <FaBusinessTime className="fontawesome"/>
                        <CenteredUnderLine><FeatureHeading> Website Maintenance</FeatureHeading></CenteredUnderLine>
                    <FeatureText> We Will Implement Updates, Changes and Provide Analytical Reports Monthly. </FeatureText>
                </Feature>
                </FeatureContainer>
                <Button to="portfolio" smooth={true} duration={500} spy={"true"} exact='true' offset={-79}> View Our Work </Button> 

                </FeaturesContainer> 
      
        </SectionWrapper>
 
    )
}

export default About
